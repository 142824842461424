import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import backgroundImage from "../assets/img/background.jpg";
import useStyles from "../styles/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  calcEst,
  showEst,
  optionsRequired,
  FONT_FAMILY,
} from "../common/sharedFunctions";
import { colors as col } from "../components/Theme/WebTheme";

const Hero = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const navigate = useNavigate();
  const role = props.role;
  const [loggedIn, setLoggedIn] = useState(false);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.profile && auth.profile.uid) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [auth.profile]);

  return (
    <Box
      className={classes.heroBox}
      sx={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "black",
        minHeight: {
          xs: "400px",
          sm: "600px",
        },
        width: "100%",
      }}
      style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
    >
      <Grid container spacing={12} className={classes.gridContainer}>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: { xs: "flex", md: "block" },
            flexDirection: { xs: "column", md: "initial" },
            alignItems: { xs: "center", md: "initial" },
            justifyContent: { xs: "center", md: "initial" },
          }}
        >
          <Typography
            variant="h3"
            fontWeight={500}
            className={classes.titleMain}
            style={{ color: col.CAR_BOX_ODD, fontFamily: FONT_FAMILY }}
            sx={{
              color: col.CAR_BOX_ODD,
              fontFamily: FONT_FAMILY,
              textAlign: { xs: "center", md: "left" },
            }}
          >
            Explore Quebec Taxi
          </Typography>
          <div
            style={{
              width: "100%",
              padding: "10px 10px 0px 10px",
              height: "auto",
              borderRadius: "20px",
              marginBottom: "10px",
              opacity: 0.8,
              margin: "0 auto",
            }}
          >
            <Typography
              variant="h6"
              className={classes.subtitleMain}
              style={{
                color: "white",
                fontFamily: FONT_FAMILY,
                opacity: 0.9,
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.9)",
              }}
            >
              {t("about_us_content2")}
            </Typography>
          </div>
          <Button
            variant="contained"
            sx={{
              width: "200px",
              fontSize: "16px",
              fontFamily: FONT_FAMILY,
              borderRadius: "50px",
              backgroundColor: "#4169e1",
              boxShadow: "0px 4px 10px rgba(25, 25, 112, 0.5)",
              "&:hover": {
                backgroundColor: "#3A2EFE",
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              if (loggedIn) {
                navigate(
                  (role && role === "driver") ||
                    (calcEst &&
                      !showEst &&
                      !optionsRequired &&
                      (role === "admin" || role === "fleetadmin"))
                    ? "/bookings"
                    : "/addbookings"
                );
              } else {
                navigate("/login");
              }
            }}
          >
            {/* Button at center in mob view done by hunaiza */}
            {role === "driver" ||
            (calcEst &&
              !showEst &&
              !optionsRequired &&
              (role === "admin" || role === "fleetadmin"))
              ? t("info")
              : t("book_your_ride_menu")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;
