import { FONT_FAMILY } from "common/sharedFunctions.js";
import { defaultFont } from "./material-kit-react.js";

import tooltip from "./tooltipsStyle.js";

const headerLinksStyle = (theme) => ({
  list: {
    ...defaultFont,
    fontSize: "14px",
    margin: 0,
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "inherit",
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    //   "&:after": {
    //     width: "calc(100% - 30px)",
    //     content: '""',
    //     display: "block",
    //     height: "1px",
    //     marginLeft: "15px",
    //     backgroundColor: "#e5e5e5",
    //   },
    // },
  },
  listItemText: {
    padding: "0 !important",
  },
  langStyle: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:after": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
      },
    },
  },
  navLink: {
    color: "inherit",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    // "&:hover,&:focus": {
    //   color: "inherit",
    //   background: "rgba(200, 200, 200, 0.2)",
    // },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start",
      },
    },
  },
  notificationNavLink: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    top: "4px",
  },
  registerNavLink: {
    top: "3px",
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
  },
  navLinkActive: {
    color: "inherit",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
  icons: {
    width: "20px",
    height: "20px",
    marginRight: "3px",
    color: "#FFFFFF",
  },
  socialIcons: {
    position: "relative",
    fontSize: "20px !important",
    marginRight: "4px",
  },
  dropdownLink: {
    "&,&:hover,&:focus": {
      color: "inherit",
      textDecoration: "none",
      display: "block",
      padding: "10px 20px",
    },
  },
  ...tooltip,
  marginRight5: {
    marginRight: "5px",
  },
  option: {
    "&:hover,&:focus": {
      background: "rgba(200, 200, 200, 0.2)",
    },
    display: "flex",
    height: "40px",
    cursor: "pointer",
    padding: "0 15px",
    borderRadius: "8px",
    alignItems: "center",
    width: "70px",
  },
  optionUl: {
    zIndex: 3000,
    position: "absolute",
    padding: "10px",
    marginTop: "10px",
    borderRadius: "8px",
    background: "#fff",
    boxShadow: "0 0 3px rgba(0,0,0,0.1)",
  },
  toggleDropdown: {
    display: "flex",
    height: "35px",
    background: "#fff",
    padding: "5px",
    fontSize: "18px",
    fontWeight: "400",
    borderRadius: "8px",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-around",
    boxShadow: "0 0 5px rgba(0,0,0,0.1)",
  },

  //Done by hunaiza
  headerButtonStyle: {
    // backgroundColor: "#84cdee",
    backgroundColor: "#4169e1",
    width: "auto",
    height: "40px",
    borderRadius: "25px",
    textAlign: "center",
    lineHeight: "40px",
    marginRight: "10px",
    marginTop: "10px",
    boxShadow: "0px 4px 10px rgba(160, 191, 240, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      // backgroundColor: "#50b8e7",
      backgroundColor: "#4169e1",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:after": {
        content: '""',
        display: "block",
        // height: "1px",
        marginLeft: "15px",
        backgroundColor: "#50b8e7",
      },
    },
  },
  headerButton: {
    textTransform: "uppercase",
    fontFamily: FONT_FAMILY,
    cursor: "pointer",
    fontSize: "13px",
    color: "#FFFFFF",
    // display: "flex",
    // alignItems: "center",
    // gap: "8px",
    transition: "font-size 0.3s ease",
    "&:hover": {
      fontSize: "14px",
    },
  },
});

export default headerLinksStyle;
