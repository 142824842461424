import { colors } from "components/Theme/WebTheme.js";
import { container, primaryColor } from "./material-kit-react.js";
import { FONT_FAMILY } from "common/sharedFunctions.js";

const footerStyle = {
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    margin: "0",
    float: "right!important",
  },
  footer: {
    padding: "0.9375rem 0",
    textAlign: "center",
    display: "flex",
    zIndex: "2",
    position: "relative",
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent",
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: colors.WHITE,
    },
  },
  container,
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px",
  },
  //Done by hunaiza
  footerButtonStyle: {
    backgroundColor: "#4169e1",
    width: "130px",
    height: "40px",
    borderRadius: "50px",
    textAlign: "center",
    lineHeight: "40px",
    marginRight: "10px",
    boxShadow: "0px 4px 10px rgba(160, 191, 240, 0.5)",
    "&:hover": {
      backgroundColor: "#4169e1",
    },
    "@media (max-width: 968px)": {
      marginRight: "10px",
      marginBottom: "10px",
      width: "25%",
    },
    "@media (max-width: 768px)": {
      marginRight: "10px",
      marginBottom: "10px",
      width: "30%",
    },
    "@media (max-width: 480px)": {
      width: "40%",
      marginBottom: "10px",
    },
  },
  footerButton: {
    textTransform: "uppercase",
    fontFamily: FONT_FAMILY,
    cursor: "pointer",
    fontSize: "11px",
    color: "#ffffff",
    transition: "font-size 0.3s ease",
    "&:hover": {
      fontSize: "12px",
    },
  },
  footerHomeButtonStyle: {
    // backgroundColor: "#B9B2AA",
    backgroundColor: "#4169e1",
    width: "130px",
    height: "auto",
    borderRadius: "50px",
    textAlign: "center",
    lineHeight: "40px",
    marginRight: "10px",
    "&:hover": {
      // backgroundColor: "#A9A9A9",
      backgroundColor: "#4169e1",
    },
    "@media (max-width: 968px)": {
      marginRight: "10px",
      marginBottom: "10px",
      width: "25%",
    },
    "@media (max-width: 768px)": {
      marginRight: "10px",
      marginBottom: "10px",
      width: "30%",
    },
    "@media (max-width: 480px)": {
      width: "30%",
      marginBottom: "10px",
    },
  },
  footerHomeButton: {
    textTransform: "uppercase",
    fontFamily: FONT_FAMILY,
    cursor: "pointer",
    fontSize: "11px",
    color: "#FFFFFF", // Done by hunaiza
    transition: "font-size 0.3s ease",
    "&:hover": {
      fontSize: "12px",
    },
    "@media (max-width: 480px)": {
      fontSize: "9px",
    },
  },
  // ***************************
};
export default footerStyle;
