export const FirebaseConfig = {
	"projectId": "exicube-taxi-8c10d",
	"appId": "1:487122222788:web:23030ea8db036a3eb0d391",
	"databaseURL": "https://exicube-taxi-8c10d-default-rtdb.firebaseio.com",
	"storageBucket": "exicube-taxi-8c10d.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCpeWMmGpT2xWi_AsUXVVr-OEeJgAtOIoU",
	"authDomain": "exicube-taxi-8c10d.firebaseapp.com",
	"messagingSenderId": "487122222788",
	"measurementId": "G-YNQBP54ZL6"
};